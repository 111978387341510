/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

require('@rails/ujs').start();

import 'bootstrap/dist/js/bootstrap';
import '@fortawesome/fontawesome-free/css/all';
import '@fortawesome/fontawesome-free/js/all';
import '@nathanvda/cocoon';

import './events';
import './toggle-if';
import './modules/cart';
import './modules/modal_messages';
import './modules/modal_spektrix_api';
import './modules/stripe_payments';
import './modules/stripe_elements';

import { initializeAllLineCharts } from './modules/charts';
import { initializeAllSortableTables } from './modules/sortable_tables';
import { initializeAllChoicesSelect } from './modules/choices_select';

// For stimulus.js
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
const application = Application.start()
const context = require.context('../controllers', true, /\.js$/)
application.load(definitionsFromContext(context))
application.debug = true

// Setup Bootstrap tooltips
import { Tooltip, Dropdown } from "bootstrap/dist/js/bootstrap";

document.addEventListener("DOMContentLoaded", () => { // or "turbolinks:load"
  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"], abbr[title]'
  );

  [...tooltipTriggerList].map((el) => new Tooltip(el));
});

document.addEventListener('DOMContentLoaded', () => {
  // do dom related stuff here
  initializeAllLineCharts();
  initializeAllSortableTables();
  initializeAllChoicesSelect();
})

document.addEventListener('DOMContentLoaded', function(event) {
  const elementPoll = $('[data-poll-url]');
  const poll_status = function() {
    const url = elementPoll.data('poll-url');
    return $.ajax({
      context: this,
      url: url,
      type: 'GET',
      dataType: 'json',
      success: function(data) {
        if (data && data.redirect_to) {
          return window.location.replace(data.redirect_to);
        }
        return setTimeout(poll_status, 1000);
      }
    });
  };
  if (elementPoll.length !== 0) {
    return setTimeout(poll_status, 1000);
  }

  let fc = 0;
  $("footer").on('click', () => {
    if (++fc >= 5) {
      $("#version").removeClass('d-none');
    }
  })
});
