import { Modal } from 'bootstrap/dist/js/bootstrap';

$(function() {
  $('[data-toggle="spektrix-api-modal"]').on('click', function(e) {
    $('#spektrix-response-modal-content').html($(this).data('content'));

    const modal = new Modal($('#spektrix-response-modal'), {});
    modal.show();
  });
});
