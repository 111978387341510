import Chart, { ChartData, ChartConfiguration } from 'chart.js/auto';
import LineWithHoverChartController from './charts/LineWithHoverChartController';
import { TinyColor } from '@ctrl/tinycolor';
import { getAppTheme } from '../application_theme';



/**
 * Returns the label for the point formatted as GBP.
 * @param ctx the tooltip context at a point
 *
 * @return A formatted label for the chart tooltip at a certain point. E.g: `<series_label>: £12.80`
 */
 const getGBPTooltipLabel = (ctx) => {
  // Put '£' before every point label
  let label = ctx.dataset.label || '';
  if (label) {
    label += ': ';
  }
  if (ctx.parsed.y !== null) {
    label += new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(ctx.parsed.y)
  }
  return label
}


/**
 * Initializes ChartJS LineChart on every canvas element with the class `line-chart`.
 *
 * Pre-requisites:
 * - The canvas element should contain `data-labels` with an array of labels ([string]) for y axis
 * - The canvas element should also contain `data-series-label` with a label (string) for the series of data passed via `data-series`
 * - `data-series` that is the array of data ([string])
 */
const initializeAllLineCharts = () => {
  const charts = document.querySelectorAll('.line-chart');
  if (!charts.length) {
    // No charts found on this page
    return;
  }

  const { palette } = getAppTheme();

  // Apply themed color and register the new chart controller type
  // so that the chartjs initialization routine can look it up
  LineWithHoverChartController.strokeColor = new TinyColor(palette.primary).setAlpha(0.32);
  Chart.register(LineWithHoverChartController);

  charts.forEach(chartCanvasEl => {
    const labels = JSON.parse(chartCanvasEl.dataset.labels || []); // displayed on y axis
    const seriesLabel = chartCanvasEl.dataset.seriesLabel; // label for the series data provided
    const series = JSON.parse(chartCanvasEl.dataset.series || []);

    /**
     * @type {ChartData}
     */
    const data = {
      labels,
      datasets: [
        {
          label: seriesLabel,
          data: series,
          fill: true,
          tension: 0.3,
          pointRadius: 6,
          pointHoverBackgroundColor: new TinyColor(palette.primary).darken().toHexString(),
          pointHoverRadius: 10,
          pointHitRadius: 20,
          pointBackgroundColor: palette.primary,
          borderColor: palette.primary,
          backgroundColor: new TinyColor(palette.primary).setAlpha(0.36)
        }
      ]
    }

    /**
     * @type {ChartConfiguration}
     */
    const config = {
      type: 'lineWithHover', // use our custom extended type
      data,
      options: {
        interaction: {
          intersect: false,
          axis: 'x'
        },
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom'
          },
          tooltip: {
            backgroundColor: palette.dark,
            padding: 12,
            usePointStyle: true,
            titleFont: {
              size: 16
            },
            bodyFont: {
              size: 14
            },
            callbacks: {
              label: (ctx) => getGBPTooltipLabel(ctx)
            }
          }
        },
        scales: {
          y: {
            min: 0,
            ticks: {
              padding: 10
            }
          }
        },
        layout: {
          padding: 10
        }
      }
    }

    new Chart(chartCanvasEl, config);
  })
}



export {
  initializeAllLineCharts
};

