import {Modal} from 'bootstrap/dist/js/bootstrap';

$(function() {
  let elements;
  const form = document.querySelector('#new_checkout_stripe_payment_proxy')

  if (!form) {
    return
  }

  const stripe = Stripe($('body').data('stripe-pub-api-key'))
  let initialized = false;
  let amount = parseInt(form.dataset.amount)

  if (amount > 0) {
    initialize();
  }
  form.addEventListener("submit", handleSubmit);

  // Fetches a payment intent and captures the client secret
  async function initialize() {
    initialized = true;
    const appearance = {
      theme: 'stripe',
    };

    let currency = 'gbp'
    if (form.hasAttribute("data-currency")) {
      currency = form.dataset.currency.toLowerCase()
    }

    const options = {
      mode: 'payment',
      amount: amount,
      currency: currency,
      paymentMethodCreation: 'manual',
      appearance
    }
    elements = stripe.elements(options);

    const paymentElementOptions = {
      layout: 'tabs',
    };

    const paymentElement = elements.create("payment", paymentElementOptions);
    paymentElement.mount("#payment-element");
  }

  document.addEventListener('cartTotal', (e) => {
    amount = parseInt(form.dataset.amount)
    const payBtn = document.querySelector('#pay-now-btn')
    const buttonTxt = document.querySelector('#button-text')

    if (amount > 0) {
      if (initialized == false) {
        initialize();
      } else {
        elements.update({amount: amount});
      }

      if (buttonTxt.innerText == 'Reserve now') {
        buttonTxt.innerText = 'Pay now'
        payBtn.classList.add('mt-3')
      }
    } else {
      if (buttonTxt.innerText == 'Pay now') {
        buttonTxt.innerText = 'Reserve now'
        payBtn.classList.remove('mt-3')
      }
    }
  })

  async function handleSubmit(e) {
    if (amount == 0) {
      return true
    }

    e.preventDefault();
    hideErrorMessage();

    // Submit needed for Google/Apple pay
    const {error: submitError} = await elements.submit();
    if (submitError) {
      showErrorMessage(submitError.message);
      return;
    }

    showPleaseWaitModal();

    // Create payment method
    stripe.createPaymentMethod({elements})
      .then(createPaymentMethodHandler)
      .catch((err) => alert(`Processing error: ${err.message}`));
    return false
  }

  // Call confirm payment with payment method ID
  function createPaymentMethodHandler(result) {
    if (result.error) {
      showErrorMessage(result.error.message);
    } else {
      confirmPayment(result.paymentMethod.id);
    }
  }

  // Confirms the payment
  function confirmPayment(stringOrObject) {
    const formData = new FormData(form);

    if (typeof stringOrObject === 'string') {
      formData.append('payment_method_id', stringOrObject)
    } else {
      formData.append('payment_intent_id', stringOrObject.payment_intent_id)
    }

    // payment_confirm_checkout_index_path
    // Checkout::StripeBaseController#confirm_payment
    fetch(form.dataset.confirmPaymentPath, {
      method: 'POST',
      body: formData
    }).then(function (response) {
      if (response.redirected) {
        return window.location.href = response.url;
      }
      response.json().then(function (json) {
        handleServerResponse(json);
      })
    }).catch((err) => alert(`Processing error: ${err.message}`));
  }

  function handleServerResponse(response) {
    if (response.redirect_to_url) {
      return window.location = response.redirect_to_url;
    } else if (response.error) {
      showErrorMessage(response.error);
    } else if (response.requires_action) {
      handleAction(response);
    } else {
      if (response.payment_intent_id) {
        form.querySelector('input[name="payment_intent_id"]').value = response.payment_intent_id;
      }
      form.submit();
    }
  }

  function handleAction(response) {
    if (response.action.type === 'use_stripe_sdk') {
      stripe.handleCardAction(response.client_secret).then(function (result) {
        const params = result.error ? {} : {payment_intent_id: result.paymentIntent.id};
        handleResponseAction(result, params);
      }).catch((err) => alert(`Processing error: ${err.message}`));
    } else if (response.action.type === 'redirect_to_url') {
      window.location = response.action.redirect_to_url.url;
    } else {
      showErrorMessage('Could not process payment, please try a different payment method.');
    }
  }

  function handleResponseAction(result, params) {
    if (result.error) {
      showErrorMessage(result.error.message);
    } else {
      confirmPayment(params);
    }
  }

  // ------- UI helpers -------

  const modalPleaseWait = new Modal(document.getElementById('modal-please-wait'), {});

  function showPleaseWaitModal() {
    document.querySelector("#pay-now-btn").disabled = true;
    document.querySelector("#button-text").classList.add("hidden");
    modalPleaseWait.show();
  }

  function hidePleaseWaitModal() {
    document.querySelector("#pay-now-btn").disabled = false;
    document.querySelector("#button-text").classList.remove("hidden");
    modalPleaseWait.hide();
  }

  function showErrorMessage(error) {
    hidePleaseWaitModal();
    const errorElement = document.querySelector('#payment-message');
    errorElement.classList.remove('d-none');
    errorElement.textContent = error;
  }

  function hideErrorMessage() {
    const errorElement = document.querySelector('#payment-message');
    errorElement.classList.add('d-none');
    errorElement.textContent = '';
  }
});
