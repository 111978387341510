import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['spinner', 'valid', 'invalid']

  connect() {
    this.#post()
  }

  #post() {
    const controller = this
    const url = '/personal_files/verify.json'

    Rails.ajax({
      type: 'POST',
      url: url,
      success: (response) => {
        controller.#hideSpinner()
        if (response.valid) {
          controller.#showValid()
        } else {
          controller.#showInvalid()
        }
      },
      error: () => {
        controller.#hideSpinner()
        controller.#showInvalid()
      }
    })
  }

  #hideSpinner() {
    this.spinnerTarget.classList.add('d-none')
  }

  #showValid() {
    this.validTarget.classList.remove('d-none')
  }

  #showInvalid() {
    this.invalidTarget.classList.remove('d-none')
  }
}
