import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input']

  // When the input checkbox is toggled, also toggle all other children checkboxes.
  toggle() {
    const childCheckboxes = this.element.querySelectorAll('input[type="checkbox"]')

    // We don't want the 'input' checkbox in the array.
    const filteredCheckboxes = Array.from(childCheckboxes).filter(
      checkbox => checkbox !== this.inputTarget
    )

    // Set all based on the 'checked' value of the inputTarget.
    filteredCheckboxes.forEach(checkbox => {
      checkbox.checked = this.inputTarget.checked
    })
  }
}
