import { Controller } from 'stimulus'
import { arrayRange, selectOptions } from '../packs/modules/array_range';

export default class extends Controller {
  static targets = [
    'quantityAvailable',
    'minAvailable',
    'maxAvailable',
    'quantityStep',
    'outputHtml',
    'outputSelect'
  ]

  connect() {
    this.render()
  }

  render() {
    this.#initial()

    const quantityAvailable = parseInt(this.quantityAvailableTarget.value)
    const minAvailable = parseInt(this.minAvailableTarget.value)
    const maxAvailable = parseInt(this.maxAvailableTarget.value)
    const quantityStep = parseInt(this.quantityStepTarget.value)

    const actualMax = Math.min(quantityAvailable, maxAvailable)

    const range = arrayRange(minAvailable, actualMax, quantityStep)
    if (!range.includes(0)) { range.unshift(0) }
    this.outputHtmlTarget.innerHTML = range

    this.outputSelectTarget.innerHTML = selectOptions(range)
  }

  // Just in case they fill in some nonsense that makes no sense
  #initial() {
    this.outputHtmlTarget.innerHTML = 0
    this.outputSelectTarget.innerHTML = '<option value="0">0</option>'
  }
}
