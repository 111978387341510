import { Controller } from 'stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  #cookieName = 'cookiesBannerAccepted'

  connect() {
    this.#showOrHideBanner()
  }

  accept() {
    Cookies.set(this.#cookieName, true, { expires: 7 })
    this.element.classList.add('d-none')
  }

  #showOrHideBanner() {
    const cookiesAllowed = Cookies.get(this.#cookieName)
    if (cookiesAllowed === 'true') {
      this.element.classList.add('d-none')
    } else {
      this.element.classList.remove('d-none')
    }
  }
}
