import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    singleCountry: { type: Boolean, default: false }
  }

  static targets = ['brandCheckbox']

  connect() {
    this.countryToggle = {}
    const uniqueCountryCodes = [
      ...new Set(this.brandCheckboxTargets.map(target => target.dataset.countryCode))
    ]
    uniqueCountryCodes.forEach(countryCode => {
      this.countryToggle[countryCode] = false
    })
  }

  selectCountry({ params: { countryCode } }) {
    this.countryToggle[countryCode] = !this.countryToggle[countryCode]

    // Only allow user to select a single country at a time.
    // So, unselect all other countries.
    if (this.singleCountryValue) {
      this.brandCheckboxTargets.forEach(target => {
        if (target.dataset.countryCode !== countryCode) {
          this.countryToggle[target.dataset.countryCode] = false
          target.checked = false
        }
      })
    }

    this.brandCheckboxTargets.forEach(target => {
      if (target.dataset.countryCode === countryCode) {
        target.checked = this.countryToggle[countryCode]
      }
    })
  }
}
