import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [
    'container',
    'button',
    'sevenDaysTotalTickets',
    'sevenDaysTotalTransacted',
    'sevenDaysTotalFees',
    'sevenDaysTotalDonations',
    'thisMonthTotalTickets',
    'thisMonthTotalTransacted',
    'thisMonthTotalFees',
    'thisMonthTotalDonations',
    'lastMonthTotalTickets',
    'lastMonthTotalTransacted',
    'lastMonthTotalFees',
    'lastMonthTotalDonations',
    'allTimeTotalTickets',
    'allTimeTotalTransacted',
    'allTimeTotalFees',
    'allTimeTotalDonations',
  ]

  static values = {
    currency: String,
    url: String
  }

  load() {
    this.buttonTarget.classList.add('d-none')
    this.containerTarget.classList.remove('d-none')

    const dateRanges = ['sevenDays', 'thisMonth', 'lastMonth', 'allTime']
    const statistics = ['TotalTickets', 'TotalTransacted', 'TotalFees', 'TotalDonations']

    dateRanges.forEach((range) => {
      statistics.forEach((stat) => {
        this.#get(range, stat)
      })
    })
  }

  #get(range, stat) {
    const controller = this
    const element = this[`${range}${stat}Target`]
    const url = `${this.urlValue}.json?currency=${this.currencyValue}&range=${range}&stat=${stat}`

    Rails.ajax({
      type: 'GET',
      url: url,
      success: (response) => {
        if (response.type === 'currency') {
          element.innerHTML = controller.#numberToCurrency(response.value)
        } else {
          element.innerHTML = response.value.toLocaleString()
        }
      },
      error: () => console.log(response)
    })
  }

  #numberToCurrency(value) {
    const options = {
        maximumFractionDigits : 2,
        currency              : this.currencyValue,
        style                 : 'currency',
        currencyDisplay       : 'symbol'
    }
    return (value || value === 0)
        ? this.#currencyToNumber(value / 100).toLocaleString('en', options)
        : '?'
  }

  #currencyToNumber(s) {
    return Number(String(s).replace(/[^0-9.-]+/g, ''))
  }
}
