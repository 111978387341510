function currencyToNumber(s) {
    return Number(String(s).replace(/[^0-9.-]+/g, ''));
}

function numberToCurrency(value, currency) {
    const options = {
        maximumFractionDigits : 2,
        currency              : currency,
        style                 : 'currency',
        currencyDisplay       : 'symbol'
    };
    return (value || value === 0)
        ? currencyToNumber(value).toLocaleString(undefined, options)
        : '£0.00';
}

$(function() {
    Promise.all(
        $('[data-event-row-id]').map(function() {
            loadEventData($(this));
        })
    ).then(function() {
        $('.event-list-rows').removeClass('d-none');
        $('.event-list-spinner').addClass('d-none');
    });

    function loadEventData(row) {
        return new Promise(function(resolve) {
            const eventId = row.data('event-row-id');
            const stringAttributes = ['tickets_sold_status'];
            const currencyAttributes = [
                'total_gross',
                'total_discounts',
                'tfg_revenue',
                'promoter_revenue',
                'total_manual_payments',
                'total_balance'
            ];
            var htmlOutput = {
                'tickets_sold_status': [],
                'total_gross': [],
                'total_discounts': [],
                'tfg_revenue': [],
                'promoter_revenue': [],
                'total_manual_payments': [],
                'total_balance': []
            }

            $.getJSON('/admin/events/' + eventId + '.json', function (response) {
                // Build up the hash of value arrays
                for (const [currency, data] of Object.entries(response['totals'])) {
                    stringAttributes.forEach(function(attr) {
                        htmlOutput[attr].push(data[attr])
                    });
                    currencyAttributes.forEach(function(attr) {
                        htmlOutput[attr].push(numberToCurrency(data[attr] / 100, currency))
                    });
                }

                // Write the values to the DOM
                for (const [attr, values] of Object.entries(htmlOutput)) {
                    const element = row.find('[data-event-attribute="' + attr + '"]')
                    element.html(values.join('<br>'))
                }
            }).then(resolve);
        });
    }
});
