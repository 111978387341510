import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = [
    'quantitySelect'
  ]

  ticketChange(e){
    const ticketId = e.target.parentElement.parentElement.dataset.ticketId;
    const ticketQuantity = e.target.value;

    const products = document.querySelectorAll(`[data-ticket-id='${ticketId}'][data-product-id]`);
    if (ticketQuantity > 0) {
      products.forEach(product => {
        product.classList.remove("d-none")
      })
    } else {
      products.forEach(product => {
        product.classList.add("d-none")
      })
    }

    this.#generateSelectTagForTicketProducts(ticketId, ticketQuantity)
  }

  #generateSelectTagForTicketProducts(ticketId, quantity) {
    const quantitySelects = this.quantitySelectTargets.filter(e =>
      e.parentElement.parentElement.dataset.ticketId == ticketId
    )

    quantitySelects.forEach(quantitySelect => {

      const productId = quantitySelect.parentElement.parentElement.dataset.productId
      const url = `/product/${productId}/quantity_max.json?quantity=${quantity}`
      const existingSelect = quantitySelect.value

      Rails.ajax({
        type: 'GET',
        url: url,
        success: (response) => {
          quantitySelect.innerHTML = ''

          const optionValues = [...Array(response.quantity_max + 1).keys()]
          const optionLabels = optionValues.map((i) => `${i}`)

          for (let index = 0; index < optionLabels.length; index++) {
            const option = document.createElement('option')
            option.value = index
            option.text = optionLabels[index]
            if (index == existingSelect) {
              option.selected = 'selected'
            } else if ((index == optionLabels.length - 1) && (existingSelect >= optionLabels.length)) {
              option.selected = 'selected'
            }
            quantitySelect.appendChild(option)
          }

        }
      })
    })
  }
}
