$(function() {

  function ToggleIf() {
    const $this = $(this);

    const showIfValue = $this.data('toggle-show-target-if');
    const currentValue = $this.val();
    const showElement = $($this.data('toggle-show-target'));

    // Only evaluate radio buttons & check boxes if they have been checked,
    // otherwise they just negate each other.
    if (($this.attr('type') == 'radio' || $this.attr('type') == 'checkbox') && $this.prop('checked') != true)
      return;

    if (String(showIfValue) == String(currentValue))
      showElement.show();
    else
      showElement.hide();

    if ($this.data('toggle-hide-target') == undefined)
      return;

    const hideElement = $($this.data('toggle-hide-target'));
    const hideIfValue = $this.data('toggle-hide-target-if');

    if (String(hideIfValue) == String(currentValue))
      hideElement.hide();
    else
      hideElement.show();
  }

  $('[data-toggle-show-target-if]').on('change', ToggleIf);
  $('[data-toggle-show-target-if]').each(ToggleIf);

  function ToggleRadioIf() {
    const $this = $(this);

    if ($this.attr('type') == 'radio' && $this.prop('checked') != true)
      return;

    const toggleClass = $this.data('toggle-class');
    const toggleValue = $this.val();

    // Hide all the target elements
    $(`.${toggleClass}`).each( function(_index, element) {
      $(element).addClass('collapse');
    });

    // Display only selected target
    $(`.${toggleClass}[data-value]`).each(function(_index, element) {
      const elementValues = String($(element).data('value')).split(',').map(x => x.trim());
      if (elementValues.includes(toggleValue)) {
        $(element).removeClass('collapse');
      }
    });
  }

  $('input.collapse-radio').on('change', ToggleRadioIf);
  $('input.collapse-radio').each(ToggleRadioIf);

  function ToggleCheckboxIf() {
    const $this = $(this);

    const toggleClass = $this.data('toggle-class');
    const toggleValue = $this.val();
    const element = $(`.${toggleClass}[data-value='${toggleValue}']`);

    if ($this.prop('checked') == true) {
      element.removeClass('collapse');
    } else {
      element.addClass('collapse');
    }
  }

  $('input.collapse-checkbox').on('change', ToggleCheckboxIf);
  $('input.collapse-checkbox').each(ToggleCheckboxIf);

  function ToggleSelectIf() {
    const $this = $(this);

    const toggleClass = $this.data('toggle-class');
    const toggleValue = $this.val();

    // Hide all the target elements
    $(`.${toggleClass}`).each( function(_index, element) {
      $(element).addClass('collapse');
    });

    // Display only selected target
    $(`.${toggleClass}[data-value]`).each(function(_index, element) {
      const elementValues = String($(element).data('value')).split(',').map(x => x.trim());
      if (elementValues.includes(toggleValue)) {
        $(element).removeClass('collapse');
      }
    });
    $(`.${toggleClass}[data-value='${toggleValue}']`).removeClass('collapse')
  }

  $('select.collapse-select').on('change', ToggleSelectIf);
  $('select.collapse-select').each(ToggleSelectIf);
});
