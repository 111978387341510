// https://www.freecodecamp.org/news/javascript-range-create-an-array-of-numbers-with-the-from-method/
const arrayRange = (start, stop, step) => {
  return Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
  )
}

const selectOptions = (range) => {
  return range.map((number) => `<option value="${number}">${number}</option>`)
}

export {
  arrayRange, selectOptions
}
