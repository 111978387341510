import { Controller } from 'stimulus'
import { Modal } from 'bootstrap/dist/js/bootstrap'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['countdown']

  connect() {
    this.#resetCountdown()
    document.addEventListener('resetBasketTimer', this.#resetCountdown.bind(this))
    this.#showInitialModal()
  }

  #resetCountdown() {
    if (this.countdownTimer) {
      clearInterval(this.countdownTimer)
    }

    Rails.ajax({
      type: 'GET',
      url: '/basket/timer',
      success: (response) => {
        this.count = parseInt(response.basket_time_remaining)
        this.countdownTarget.textContent = this.#formatTimeAsMMSS(this.count)

        if (this.count > 0) {
          const fiveMinutesInSeconds = 60 * 5

          this.countdownTimer = setInterval(() => {
            this.count--
            this.countdownTarget.textContent = this.#formatTimeAsMMSS(this.count)

            if (this.count === 0) {
              clearInterval(this.countdownTimer)
            } else if (this.count === fiveMinutesInSeconds) {
              this.#showTimerModal()
            }
          }, 1000)
        } else {
          this.countdownTarget.innerHTML = '0:00'
        }
      }
    })
  }

  #showTimerModal() {
    this.#showModal('modal-basket-timer')
  }

  #showInitialModal() {
    this.#showModal('modal-basket-initial')
  }

  #showModal(elementId) {
    const element = document.getElementById(elementId)
    if (!element) { return }

    const modal = new Modal(element, {})
    modal.show()
  }

  #formatTimeAsMMSS(seconds) {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    const formattedMinutes = `${minutes}`
    const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`
    return `${formattedMinutes}:${formattedSeconds}`
  }
}
