import Chart from 'chart.js/auto';
import { LineController } from 'chart.js';

/**
 * Line chart extension which includes a dashed line on x axis (vertically) while hovering over a point.
 */
class LineWithHoverChartController extends LineController {
  static strokeColor = '#E5E5E5';

  draw() {
    // Call bubble controller method to draw all the points
    if (this.chart.tooltip.getActiveElements().length > 0) {
      const activePoint = this.chart.tooltip.getActiveElements()[0];
      const ctx = this.chart.ctx;
      const x = activePoint.element.x;
      const topY = this.chart.chartArea.top
      const bottomY = this.chart.chartArea.bottom;

      // draw line
      ctx.save();
      ctx.beginPath();
      ctx.setLineDash([5, 3])
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 2;
      ctx.strokeStyle = LineWithHoverChartController.strokeColor;
      ctx.stroke();
      ctx.restore();
    }

    super.draw(arguments);
  }
};

// Configure the value/name for the chart configuration 'type' property
LineWithHoverChartController.id = 'lineWithHover';
LineWithHoverChartController.defaults = LineController.defaults;
export default LineWithHoverChartController;
