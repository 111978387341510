import { Controller } from 'stimulus'

// https://www.sledgeworx.io/previewing-image-uploads-with-stimulus-js/
export default class extends Controller {
  static targets = [ 'output', 'input' ]

  render() {
    const input = this.inputTarget
    const output = this.outputTarget

    if (input.files && input.files[0]) {
      const reader = new FileReader()
      reader.onload = () => output.src = reader.result
      reader.readAsDataURL(input.files[0])
    }
  }
}
