import { Controller } from 'stimulus'
import Tagify from '@yaireo/tagify'

export default class extends Controller {
  static targets = ['input']

  tagifiedInput = null

  connect() {
    this.#createInitialElement()
    this.#requestWhitelist()
  }

  // Quickly create a tagified input, so it isn't ugly before the AJAX completes.
  #createInitialElement() {
    this.tagifiedInput = new Tagify(this.inputTarget)
  }

  #requestWhitelist() {
    const ajax = new XMLHttpRequest()
    ajax.onload = this.#createFullElement(this)
    ajax.open('GET', '/genres.json', true)
    ajax.send()
  }

  #createFullElement(instance) {
    return function(response) {
      const responseJSON = JSON.parse(response.target.responseText)
      instance.tagifiedInput.destroy()
      instance.tagifiedInput = new Tagify(
        instance.inputTarget,
        {
          enforceWhitelist: true,
          whitelist: responseJSON,
          dropdown: {
            maxItems: 20,
            classname: 'tags-look',
            enabled: 0,
            closeOnSelect: false
          }
        }
      )
    }
  }
}
