/**
 * @file Application theme which is automatically extracted from Bootsrap 5 CSS framework applied.
 */

/**
 * @typedef {object} AppTheme
 * @property {object} palette - Brand palette colors
 */

/**
 * Returns the default application theme object
 * @return {AppTheme}
 */
const getAppTheme = () => {
  const style = getComputedStyle(document.body);
  return {
    palette: {
      primary: style.getPropertyValue('--bs-primary'),
      primaryLight: style.getPropertyValue('--bs-primary-light'),
      primaryDark: style.getPropertyValue('--bs-primary-dark'),
      secondary: style.getPropertyValue('--bs-secondary'),
      success: style.getPropertyValue('--bs-success'),
      warning: style.getPropertyValue('--bs-warning'),
      danger: style.getPropertyValue('--bs-danger'),
      light: style.getPropertyValue('--bs-light'),
      info: style.getPropertyValue('--bs-info'),
      dark: style.getPropertyValue('--bs-dark'),
      blue: style.getPropertyValue('--bs-blue'),
      indigo: style.getPropertyValue('--bs-indigo'),
      purple: style.getPropertyValue('--bs-purple'),
      pink: style.getPropertyValue('--bs-pink'),
      red: style.getPropertyValue('--bs-red'),
      orange: style.getPropertyValue('--bs-orange'),
      yellow: style.getPropertyValue('--bs-yellow'),
      green: style.getPropertyValue('--bs-green'),
      teal: style.getPropertyValue('--bs-teal'),
      cyan: style.getPropertyValue('--bs-cyan'),
      white: style.getPropertyValue('--bs-white'),
      gray: {
        default: style.getPropertyValue('--bs-gray'),
        dark: style.getPropertyValue('--bs-gray-dark'),
        100: style.getPropertyValue('--bs-gray-100'),
        200: style.getPropertyValue('--bs-gray-200'),
        300: style.getPropertyValue('--bs-gray-300'),
        400: style.getPropertyValue('--bs-gray-400'),
        500: style.getPropertyValue('--bs-gray-500'),
        600: style.getPropertyValue('--bs-gray-600'),
        700: style.getPropertyValue('--bs-gray-700'),
        800: style.getPropertyValue('--bs-gray-800'),
        900: style.getPropertyValue('--bs-gray-900'),
      }
    }
  };
}

export {
  getAppTheme
}
