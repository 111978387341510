import { Modal } from 'bootstrap/dist/js/bootstrap'

$(function() {
  const modalIds = [
    'modal-login-prompt',
    'modal-stay-in-touch',
    'modal-unverified-message',
  ]

  modalIds.forEach((modalId) => {
    const element = document.getElementById(modalId)
    if (element) {
      const modal = new Modal(element, {})
      modal.show()
    }
  })
})
