import Choices from '@kishannareshpal/choices.js';


/**
 * Initializes ChoicesJS select boxes on every input element with the class `choices-select`.
 *
 * Optionally you can:
 *  - add `date-no-results-text` for the text that is shown when a user's search has returned no results. Defaults to "No results found"
 */
const initializeAllChoicesSelect = () => {
  const selects = document.querySelectorAll('.choices-select');
  if (!selects.length) {
    // No selects found on this page
    return;
  }

  const opts = {
    placeholder: true,
    searchPlaceholderValue: 'Select an option, or start typing to filter..',
    classNames: {
      listSingle: 'choices__list--single py-0',
      containerInner: 'form-control',
      containerInnerFocusState: 'form-control--manual-focus'
    }
  }

  selects.forEach(selectEl => {
    const noResultsText = selectEl.dataset.noResultsText;
    const containerInnerExtraClass = selectEl.dataset.containerInnerExtraClass;

    if (noResultsText) opts['noResultsText'] = noResultsText
    if (containerInnerExtraClass) opts.classNames.containerInner += ` ${containerInnerExtraClass}`

    new Choices(selectEl, opts);
  })
}

export {
  initializeAllChoicesSelect
}
