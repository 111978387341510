import {Modal} from 'bootstrap/dist/js/bootstrap';

$(document).ready(function () {
  let elements, style;
  const form = document.querySelector('[data-stripe-payment-form]');

  if (!form) {
    return;
  }

  const stripe = Stripe($('body').data('stripe-pub-api-key'));
  let cardNumber, cardCvc, cardExpiry;
  let idealBank;

  if (form.querySelector('#card-number-element')) {
    elements = stripe.elements();

    style = {
      base: {
        fontSize: '14px',
        lineHeight: '1.6'
      }
    };

    cardNumber = elements.create('cardNumber', {
      style: style,
      placeholder: ''
    });

    cardCvc = elements.create('cardCvc', {
      style: style,
      placeholder: ''
    });

    cardExpiry = elements.create('cardExpiry', {
      style: style
    });

    cardNumber.addEventListener('change', handleElementError);
    cardCvc.addEventListener('change', handleElementError);
    cardExpiry.addEventListener('change', handleElementError);
  }

  if (form.querySelector('#ideal-bank-element')) {
    idealBank = elements.create('idealBank', {
      style: style
    });
  }

  const cardTypeInput = form.querySelector('#checkout_stripe_proxy_card_type');
  if (cardTypeInput) {
    cardTypeInput.addEventListener('change', handleCardTypeChange);
    handleCardTypeChange({target: cardTypeInput});
  }

  function paymentMethodOptions() {
    if (cardTypeInput.value === 'ideal') {
      return {
        type: 'ideal',
        ideal: idealBank,
        billing_details: {
          name: document.querySelector('#checkout_stripe_proxy_name').value || null
        }
      }
    }

    return {
      type: 'card',
      card: cardNumber,
      billing_details: {
        name: document.querySelector('#checkout_stripe_proxy_card_holder').value || null
      }
    }
  }

  form.addEventListener('submit', function (event) {
    const noCardNeeded = $(event.target).attr('novalidate') !== undefined;
    if (noCardNeeded) return true;

    if (needPaymentInfo()) {
      event.preventDefault();
      showPleaseWaitModal();
      hideErrorMessage();

      stripe.createPaymentMethod(paymentMethodOptions())
        .then(createPaymentMethodHandler)
        .catch((err) => alert(`Processing error: ${err.message}`));
      return false;
    }
    return true;
  });

  function handleCardTypeChange(event) {
    if (event.target.value === 'card') {
      $('.ideal').hide();
      $('.card').show();

      if (form.querySelector('#card-number-element')) {
        cardNumber.mount('#card-number-element');
        cardCvc.mount('#card-cvc-element');
        cardExpiry.mount('#card-expiry-element');
        document.querySelector('#checkout_stripe_proxy_card_holder').disabled = false;
      }
      if (form.querySelector('#ideal-bank-element')) {
        idealBank.unmount('#ideal-bank-element');
        document.querySelector('#checkout_stripe_proxy_name').disabled = true;
      }
    } else if (event.target.value === 'ideal') {
      $('.card').hide();
      $('.ideal').show();

      if (form.querySelector('#card-number-element')) {
        cardNumber.unmount('#card-number-element');
        cardCvc.unmount('#card-cvc-element');
        cardExpiry.unmount('#card-expiry-element');
        document.querySelector('#checkout_stripe_proxy_card_holder').disabled = true;
      }
      if (form.querySelector('#ideal-bank-element')) {
        idealBank.mount('#ideal-bank-element');
        document.querySelector('#checkout_stripe_proxy_name').disabled = false;
      }
    }
  }

  function handleElementError(event) {
    hidePleaseWaitModal();
    const errorElement = form.querySelector('.element-error[data-element-type="' + event.elementType + '"]');
    if (event.error) {
      errorElement.textContent = event.error.message;
    } else {
      errorElement.textContent = '';
    }
  }

  function createPaymentMethodHandler(result) {
    if (result.error) {
      showErrorMessage(result.error.message);
    } else {
      const params = {payment_method_id: result.paymentMethod.id};
      confirmPayment(params);
    }
  }

  function confirmPayment(params) {
    const formData = new FormData(form);
    for (let key in params) {
      formData.append(key, params[key]);
    }
    fetch(form.dataset.paymentConfirmationPath, {
      method: 'POST',
      body: formData
    }).then(function (response) {
      if (response.redirected) {
        window.location.href = response.url;
        return;
      }
      response.json().then(function (json) {
        handleServerResponse(json);
      })
    }).catch((err) => alert(`Processing error: ${err.message}`));
  }

  function handleServerResponse(response) {
    if (response.error) {
      showErrorMessage(response.error);
    } else if (response.requires_action) {
      handleAction(response);
    } else {
      if (response.payment_intent_id) {
        form.querySelector('input[name="payment_intent_id"]').value = response.payment_intent_id;
      }
      form.submit();
    }
  }

  function handleAction(response) {
    if (response.action.type === 'use_stripe_sdk') {
      stripe.handleCardAction(response.client_secret).then(function (result) {
        const params = result.error ? {} : {payment_intent_id: result.paymentIntent.id};
        handleResponseAction(result, params);
      }).catch((err) => alert(`Processing error: ${err.message}`));
    } else if (response.action.type === 'redirect_to_url') {
      window.location = response.action.redirect_to_url.url;
    } else {
      showErrorMessage('Could not process payment, please try a different payment method.');
    }
  }

  function handleResponseAction(result, params) {
    if (result.error) {
      showErrorMessage(result.error.message);
    } else {
      confirmPayment(params);
    }
  }

  function showErrorMessage(error) {
    hidePleaseWaitModal();
    const errorElement = document.querySelector('#stripe-error');
    errorElement.classList.remove('d-none');
    errorElement.textContent = error;
  }

  function hideErrorMessage() {
    const errorElement = document.querySelector('#stripe-error');
    errorElement.classList.add('d-none');
    errorElement.textContent = '';
  }

  function needPaymentInfo() {
    hidePleaseWaitModal();
    return form.querySelector('#card-number-element') || form.querySelector('#ideal-bank-element');
  }

  const modalPleaseWait = new Modal(document.getElementById('modal-please-wait'), {});

  function showPleaseWaitModal() {
    document.querySelector("input[type='submit'][form='" + form.id + "']").disabled = true;
    modalPleaseWait.show();
  }

  function hidePleaseWaitModal() {
    modalPleaseWait.hide();
    document.querySelector("input[type='submit'][form='" + form.id + "']").disabled = false;
  }
});
