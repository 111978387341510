import Sortable from 'sortablejs';

/**
 * SortableJS opts used on every table initialized as sortable.
 * @type {Sortable.Options}
 */
const sortableOpts = {
  handle: '.sortable-table-handle',
  ghostClass: 'bg-primary-light',
  chosenClass: 'sortable-table-dragging',
  easing: "cubic-bezier(1, 0, 0, 1)", // a sleek anim on move ;)
  animation: 150,
  onUpdate: (event) => {
    const tableBodyEl = event.target;
    const url = tableBodyEl.dataset.updateUrl;
    if (!url) return;

    // Grab the new IDs in the new order from the table.
    const tableRows = tableBodyEl.querySelectorAll('tr');
    const idsInNewOrder = [...tableRows].map(tr => tr.dataset.id);

    // Apply the sorted list
    const reqOpts = {
      type: 'POST',
      beforeSend: (xhr) => {
        // Put the csrf is being set on the request header
        const csrfTokenMetaTagEl = document.querySelector('meta[name="csrf-token"]');
        let csrfToken = csrfTokenMetaTagEl ? csrfTokenMetaTagEl.getAttribute('content') : '';
        xhr.setRequestHeader('X-CSRF-Token', csrfToken);
      },
      data: {
        ids_in_new_order: idsInNewOrder
      }
    }
    $.ajax(url, reqOpts);
  }
};


/**
 * Initializes SortableJS on all `<table>` elements with the class `.sortable-table` on it's `<tbody>`.
 *
 * Pre-requisites:
 * - `<tbody>` should have a `data-update-url` attribute with the url path to POST to when the row order is changed (sorted!)
 * - Each `<tr>` should contain a `data-id` with the id of the ActiveRecord (model) as it's value.
 * - There should be a `<th>` inside each `<tr>` with the class of `.sortable-table-handle`, that is, the handle for dragging the row.
 */
const initializeAllSortableTables = () => {
  const tables = document.querySelectorAll('.sortable-table');
  tables.forEach(table => new Sortable(table, sortableOpts));
}

export {
  initializeAllSortableTables
}
