function currencyToNumber(s) {
  return Number(String(s).replace(/[^0-9.-]+/g, ''));
}

function numberToCurrency(value, currency) {
  currency = currency || 'GBP';
  const options = {
    maximumFractionDigits : 2,
    currency              : currency,
    style                 : 'currency',
    currencyDisplay       : 'symbol'
  };
  return (value || value === 0)
    ? currencyToNumber(value).toLocaleString('en', options)
    : '£0.00';
}

$(function() {
  const form = $('form.edit_cart select');
  if (form.length < 1) { return };

  // When either select changes, POST to the server and update the price breakdown.
  $('form.edit_cart select').change(function() {

    // Grab the form's URL and entered data.
    const url = $(this.form).attr('action');
    const donation = $(this.form).find('select.select-donation').val();
    const data = {};
    data['cart'] = {};
    data['cart']['donation'] = donation;

    const updatePriceBreakdown = function(result) {
      const donation = numberToCurrency(result.donation / 100, result.currency_code);
      const total = numberToCurrency(result.total_price / 100, result.currency_code);
      $('.donation-price').text(donation);
      $('.total-price').text(total);
    };

    const toggleStripeCardDetails = function(result) {
      const details = $('.stripe-card-details');
      const form = details.closest('form');
      if (result.total_price > 0) {
        details.removeClass('d-none');
        form.removeAttr('novalidate');
      } else {
        details.addClass('d-none');
        form.attr('novalidate', 'novalidate');
      }
    };

    $.ajax({
      url: url,
      type: 'PATCH',
      dataType: 'json',
      data: data,
      success: function(result) {
        updatePriceBreakdown(result);
        toggleStripeCardDetails(result);
      }
    });
  });
});
